import { withZod } from "@remix-validated-form/with-zod";
import { BaseObject } from "@thedevshop/core";
import { z } from "zod";

export const appSubmissionSchema = z.object({
    name: z
      .string()
      .min(3, { message: 'Name is required' }),
  
    description: z
      .string()
      .min(3, { message: 'Description is required' }),
  
    category: z
      .string()
      .min(1, { message: 'Category is required' }),
  });

  export const appSubmissionValidator = withZod(appSubmissionSchema);


  export interface IAppSubmission extends z.infer<typeof appSubmissionSchema>, BaseObject {  }


  export const subscriptionSchema = z.object({
    email: z
      .string()
      .email({ message: 'Email is required' }),
  });

  
  export const subscriptionValidator = withZod(subscriptionSchema);

  export interface ISubscriptionRequest extends z.infer<typeof subscriptionSchema>, BaseObject {
  }


  export const betaSubmissionSchema = z.object({
    firstName: z
      .string()
      .min(2, { message: 'First Name is required' }),
    lastName: z
      .string()
      .min(2, { message: 'Last Name is required' }),
    email: z
      .string().email()
      .min(1, { message: 'Email is required' }),
    organization: z
      .string()
      .min(1, { message: 'Organization is required' }),
    notes: z
      .string().optional(),
  
   
  });

  export const betaSubmissionValidator = withZod(betaSubmissionSchema);

  export interface IBetaSubmission extends z.infer<typeof betaSubmissionSchema>, BaseObject {  }
